import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import * as moment from "moment/moment";
import {TIPOS_DOCUMENTOS} from "../../data/datos.constants";
import {Eps} from "../../models/eps.model";
import {Regimen} from "../../models/regimen.model";
import {ApiService} from "../../services/api.service";
import {TokenStorageService} from "../../services/auth/token-storage.service";
import {Title} from "@angular/platform-browser";
import {NAME_APP} from "../../url.constants";
import {
  ModalJuntaMedicaDetalleComponent
} from "../modals/modal-junta-medica-detalle/modal-junta-medica-detalle.component";

@Component({
  selector: 'app-main-junta-medicas',
  templateUrl: './main-junta-medicas.component.html',
  styleUrls: ['./main-junta-medicas.component.scss']
})
export class MainJuntaMedicasComponent implements OnInit {

  now = moment();
  filtrado: string = "f";
  fecha: string = this.now.format("YYYY-MM-DD");
  fechaAfiliado: string = this.now.format("YYYY-MM-DD");
  tipoDocumento: string = "CC";
  documento: string;
  prescripcion: string;
  primera: boolean = false;
  cargando: boolean = false;

  noDirs: any[] = [];

  tipos = TIPOS_DOCUMENTOS;

  eps: Eps = null;

  regimen: Regimen = null;

  @ViewChild("modalJuntaMedicaDetalle", { static: false })
  modalJuntaMedicaDetalle: ModalJuntaMedicaDetalleComponent;

  @ViewChild("showModalJuntaMedica", { static: false })
  showModalJuntaMedica: ElementRef;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Juntas Médicas`);
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
    this.apiService.utilService.buscarEnTabla();
  }

  cambiarFiltro(f: string) {
    this.noDirs = [];
    this.filtrado = f;
  }

  busqueda() {
    this.noDirs = [];
    this.cargando = true;
    // GENERAR TABLA
    if (!this.eps) {
      return;
    }
    switch (this.filtrado) {
      case "f":
        this.apiService.juntaMedicaService
          .getPorFecha(this.eps.identificacion, this.regimen.codigo, this.fecha)
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.noDirs = data;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "a":
        this.apiService.juntaMedicaService
          .getPorPaciente(
            this.tipoDocumento,
            this.documento,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaAfiliado
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.noDirs = data;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "p":
        this.apiService.juntaMedicaService
          .getPorNumero(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.noDirs = data;
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );
        break;
      default:
        break;
    }
  }

  detalle(noDir: any) {
    this.modalJuntaMedicaDetalle.noDireccionamiento = noDir;
    this.showModalJuntaMedica.nativeElement.click();
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
