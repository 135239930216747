import { MainCargueCodigosComponent } from './components/main-cargue-codigos/main-cargue-codigos.component';
import { MainInformeRecobrosComponent } from './components/main-informe-recobros/main-informe-recobros.component';
import { MainReportesEntregasComponent } from './components/main-reportes-entregas/main-reportes-entregas.component';
import { MainTutelasComponent } from './components/main-tutelas/main-tutelas.component';
import { MainPrescripcionesComponent } from "./components/main-prescripciones/main-prescripciones.component";
import { MainTokenComponent } from "./components/main-token/main-token.component";
import { MainEpsEditComponent } from "./components/main-eps-edit/main-eps-edit.component";
import { MainEpsAddComponent } from "./components/main-eps-add/main-eps-add.component";
import { MainEpssComponent } from "./components/main-epss/main-epss.component";
import { MainUsuarioComponent } from "./components/main-usuario/main-usuario.component";
import { MainUsuarioEditComponent } from "./components/main-usuario-edit/main-usuario-edit.component";
import { MainUsuarioAddComponent } from "./components/main-usuario-add/main-usuario-add.component";
import { MainUsuariosComponent } from "./components/main-usuarios/main-usuarios.component";
import { MainInicioComponent } from "./components/main-inicio/main-inicio.component";
import { MainComponent } from "./components/main/main.component";
import { InicioSignInComponent } from "./components/inicio-sign-in/inicio-sign-in.component";
import { InicioComponent } from "./components/inicio/inicio.component";
import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { MainReporteDireccionamientosComponent } from './components/main-reporte-direccionamientos/main-reporte-direccionamientos.component';
import { MainSuministrosComponent } from './components/main-suministros/main-suministros.component';
import { MainInformeAutorizacionesComponent } from './components/main-informe-autorizaciones/main-informe-autorizaciones.component';
import { MainTarifasMensualComponent } from './components/main-tarifas-mensual/main-tarifas-mensual.component';
import { MainTarifasMensualInformeComponent } from './components/main-tarifas-mensual-informe/main-tarifas-mensual-informe.component';
import { MainNoDireccionamientosComponent } from './components/main-no-direccionamientos/main-no-direccionamientos.component';
import { MainNoDireccionamientosReporteComponent } from './components/main-no-direccionamientos-reporte/main-no-direccionamientos-reporte.component';
import { MainValidadorPrescripcionesComponent } from './components/main-validador-prescripciones/main-validador-prescripciones.component';
import { MainReporteDinamicoComponent } from './components/main-reporte-dinamico/main-reporte-dinamico.component';
import {MainTareasProgramadasComponent} from './components/main-tareas-programadas/main-tareas-programadas.component';
import {MainReporteJuntasComponent} from "./components/main-reporte-juntas/main-reporte-juntas.component";
import {MainJuntaMedicasComponent} from "./components/main-junta-medicas/main-junta-medicas.component";

const routes: Routes = [
  {
    path: "inicio",
    component: InicioComponent,
    children: [
      { path: "", component: InicioSignInComponent },
      { path: "signin", component: InicioSignInComponent }
    ]
  },
  { path: "", redirectTo: "inicio", pathMatch: "full" },
  {
    path: "main",
    component: MainComponent,
    children: [
      { path: "", component: MainInicioComponent },
      { path: "perfil", component: MainUsuarioComponent },

      { path: "usuarios", component: MainUsuariosComponent },
      { path: "usuario-add", component: MainUsuarioAddComponent },
      { path: "usuario-edit", component: MainUsuarioEditComponent },

      { path: "eps", component: MainEpssComponent },
      { path: "eps-add", component: MainEpsAddComponent },
      { path: "eps-edit", component: MainEpsEditComponent },

      { path: "token", component: MainTokenComponent },
      { path: "codigos", component: MainCargueCodigosComponent },

      { path: "prescripciones", component: MainPrescripcionesComponent },
      { path: "suministros", component: MainSuministrosComponent },
      { path: "tutelas", component: MainTutelasComponent },
      { path: "reportes-entregas", component: MainReportesEntregasComponent },
      { path: "no-direccionamientos", component: MainNoDireccionamientosComponent },
      { path: 'junta-medica', component: MainJuntaMedicasComponent },
      { path: "no-direccionamientos-reporte", component: MainNoDireccionamientosReporteComponent },

      { path: 'reportes', component: MainReporteDireccionamientosComponent },
      { path: 'informe', component: MainInformeAutorizacionesComponent },
      { path: 'dinamico', component: MainReporteDinamicoComponent },
      { path: 'recobros', component: MainInformeRecobrosComponent },
      { path: 'juntas-medicas', component: MainReporteJuntasComponent },

      { path: 'consumos-mensual', component: MainTarifasMensualComponent },
      { path: 'informe-consumos-mensual', component: MainTarifasMensualInformeComponent },

      { path: 'validador-prescripciones', component: MainValidadorPrescripcionesComponent },

      { path: 'tareas', component: MainTareasProgramadasComponent },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {}
