import { CausaNoEntrega } from "./../../../models/causa-no-entrega.model";
import { FormBuilder, Validators } from "@angular/forms";
import { ApiService } from "./../../../services/api.service";
import { TutelaApi } from "src/app/models/mpresc/tutela-api.model";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { NoDireccionamientoSalida } from "./../../../models/salida/no-direccionamiento-salida.model";
import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef
} from "@angular/core";
import { Regimen } from "src/app/models/regimen.model";
import { Eps } from "src/app/models/eps.model";
import { FormGroup } from "@angular/forms";
import Swal from "sweetalert2";
import {ModalMedicamentoDetalleComponent} from "../modal-medicamento-detalle/modal-medicamento-detalle.component";
import {
  ModalServicioComplementarioDetalleComponent
} from "../modal-servicio-complementario-detalle/modal-servicio-complementario-detalle.component";
import {ModalProcedimientoDetalleComponent} from "../modal-procedimiento-detalle/modal-procedimiento-detalle.component";
import {
  ModalProductoNutricionalDetalleComponent
} from "../modal-producto-nutricional-detalle/modal-producto-nutricional-detalle.component";
import {
  ModalDispositivoMedicoDetalleComponent
} from "../modal-dispositivo-medico-detalle/modal-dispositivo-medico-detalle.component";
import {
  ModalMedicamentoTutDetalleComponent
} from "../modal-medicamento-tut-detalle/modal-medicamento-tut-detalle.component";
import {
  ModalServicioComplementarioTutDetalleComponent
} from "../modal-servicio-complementario-tut-detalle/modal-servicio-complementario-tut-detalle.component";
import {
  ModalProcedimientoTutDetalleComponent
} from "../modal-procedimiento-tut-detalle/modal-procedimiento-tut-detalle.component";
import {
  ModalProductoNutricionalTutDetalleComponent
} from "../modal-producto-nutricional-tut-detalle/modal-producto-nutricional-tut-detalle.component";
import {
  ModalDispositivoMedicoTutDetalleComponent
} from "../modal-dispositivo-medico-tut-detalle/modal-dispositivo-medico-tut-detalle.component";
import {invalidInputGeneral} from "../../../data/datos.constants";

@Component({
  selector: "app-modal-no-direccionamiento-add",
  templateUrl: "./modal-no-direccionamiento-add.component.html",
  styleUrls: ["./modal-no-direccionamiento-add.component.scss"]
})
export class ModalNoDireccionamientoAddComponent implements OnInit {
  @Input() public salida: NoDireccionamientoSalida;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Output() public showModalPrescripcion = new EventEmitter();
  @Output() public showModalTutela = new EventEmitter();

  direccionamientoForm: FormGroup;

  salidaEdit: NoDireccionamientoSalida = null;
  causasNoEntrega: CausaNoEntrega[] = [];
  causaNoEntrega: CausaNoEntrega = null;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  @ViewChild("modalMedicamentoDir", { static: false })
  modalMedicamento: ModalMedicamentoDetalleComponent;

  @ViewChild("modalServicioComplDir", { static: false })
  modalServicioCompl: ModalServicioComplementarioDetalleComponent;

  @ViewChild("modalProcedimientoDir", { static: false })
  modalProcedimiento: ModalProcedimientoDetalleComponent;

  @ViewChild("modalProductoNutricDir", { static: false })
  modalProductoNutric: ModalProductoNutricionalDetalleComponent;

  @ViewChild("modalDispositivoMedicDir", { static: false })
  modalDispositivoMedic: ModalDispositivoMedicoDetalleComponent;

  @ViewChild("modalMedicamentoTutDir", { static: false })
  modalMedicamentoTut: ModalMedicamentoTutDetalleComponent;

  @ViewChild("modalServicioComplTutDir", { static: false })
  modalServicioComplTut: ModalServicioComplementarioTutDetalleComponent;

  @ViewChild("modalProcedimientoTutDir", { static: false })
  modalProcedimientoTut: ModalProcedimientoTutDetalleComponent;

  @ViewChild("modalProductoNutricTutDir", { static: false })
  modalProductoNutricTut: ModalProductoNutricionalTutDetalleComponent;

  @ViewChild("modalDispositivoMedicTutDir", { static: false })
  modalDispositivoMedicTut: ModalDispositivoMedicoTutDetalleComponent;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  ngOnInit() {
    if (this.eps) {
      this.apiService.codigosMipresService
        .getCausasNoEntregaPorTipo(this.salida.TipoTec)
        .subscribe(data => {
          this.causasNoEntrega = data;
          this.causaNoEntrega = this.causasNoEntrega
            ? this.causasNoEntrega[0]
            : null;
        });
      this.direccionamientoForm = this.fb.group({
        NoPrescripcionAsociada: [],
        ConTecAsociada: []
      });
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    if (this.prescripcion) {
      this.showModalPrescripcion.emit(this.prescripcion);
    }
    if (this.tutela) {
      this.showModalTutela.emit(this.tutela);
    }
  }

  detalleMedicamento(tut = false) {
    if (tut) {
      this.modalMedicamentoTut.medicamento = this.salida.data;
    } else {
      this.modalMedicamento.medicamento = this.salida.data;
    }
  }

  detalleServicioCompl(tut = false) {
    if (tut) {
      this.modalServicioComplTut.servicioCompl = this.salida.data;
    } else {
      this.modalServicioCompl.servicioCompl = this.salida.data;
    }
  }

  detalleProcedimiento(tut = false) {
    if (tut) {
      this.modalProcedimientoTut.procedimiento = this.salida.data;
    } else {
      this.modalProcedimiento.procedimiento = this.salida.data;
    }
  }

  detalleProductoNutri(tut = false) {
    if (tut) {
      this.modalProductoNutricTut.productoNutr = this.salida.data;
    } else {
      this.modalProductoNutric.productoNutr = this.salida.data;
    }
  }

  detalleDispositivoMedic(tut = false) {
    if (tut) {
      this.modalDispositivoMedicTut.dispositivo = this.salida.data;
    } else {
      this.modalDispositivoMedic.dispositivo = this.salida.data;
    }
  }

  onSubmit() {
    this.salidaEdit = Object.assign({}, this.direccionamientoForm.value);

    this.salidaEdit.ConTec = this.salida.ConTec;
    this.salidaEdit.TipoTec = this.salida.TipoTec;
    this.salidaEdit.NoPrescripcion = this.salida.NoPrescripcion;

    this.salidaEdit.TipoIDPaciente = this.salida.TipoIDPaciente;
    this.salidaEdit.NoIDPaciente = this.salida.NoIDPaciente;

    this.salidaEdit.CausaNoEntrega = this.causaNoEntrega.codigo;

    this.apiService.noDireccionamientosService
      .enviar(this.eps.identificacion, this.regimen.codigo, this.salidaEdit)
      .subscribe(
        data => {
          console.log(data);
          Swal.fire({
            title: "Hecho",
            type: "success",
            text: "El no direccionamiento se ha realizado con exito"
          });
          if (this.prescripcion) {
            this.apiService.utilService.realizadoAuditoriaPrescDetalle(this.prescripcion, this.salida.TipoTec, this.salida.ConTec);
          } else {
            this.apiService.utilService.realizadoAuditoriaTutelaDetalle(this.tutela, this.salida.TipoTec, this.salida.ConTec);
          }
          this.hideModal();
        },
        error => {
          console.log(error);
          this.apiService.notifService.error("Error", error);
        }
      );
  }

  getDescTipo(tipo: string) {
    switch (tipo) {
      case "M":
        return "Medicamento";
      case "D":
        return "Dispositivo";
      case "P":
        return "Procedimiento";
      case "N":
        return "Producto nutricional";
      case "S":
        return "Servicio complementario";
      default:
        return "";
    }
  }

  protected readonly invalidInputGeneral = invalidInputGeneral;
}
