import { TokenStorageService } from 'src/app/services/auth/token-storage.service';
import { ApiService } from 'src/app/services/api.service';
import { Eps } from 'src/app/models/eps.model';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import {ModalRegistrarTareaComponent} from '../modals/modal-registrar-tarea/modal-registrar-tarea.component';
import {Regimen} from '../../models/regimen.model';

@Component({
  selector: 'app-main-tareas-programadas',
  templateUrl: './main-tareas-programadas.component.html',
  styles: []
})
export class MainTareasProgramadasComponent implements OnInit {

  now = moment();
  eps: Eps = null;
  fechaBusqueda: string = this.now.format('YYYY-MM-DD');
  cargando = false;

  tareas = [];

  showModal = false;
  @ViewChild('modalagregarmasivo', { static: false }) modalAgregarMasivo: ModalRegistrarTareaComponent;
  @ViewChild('closeModal', {static: true}) closeModal: ElementRef<HTMLElement>;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService) { }

  ngOnInit() {
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
      }
    });
  }

  agregarMasivo() {
    this.modalAgregarMasivo.eps = this.eps;
    this.modalAgregarMasivo.ngOnInit();
  }

  formatFecha(fecha) {
    const f = moment(fecha);
    if (f.isValid()) {
      return f.format('YYYY-MM-DD HH:mm');
    }
    return '';
  }

  busqueda() {
    this.showModal = false;
    const el: HTMLElement = this.closeModal.nativeElement;
    el.click();
    this.cargando = true;
    this.apiService.tareasService.listadoCargues(this.eps.identificacion, this.fechaBusqueda)
      .subscribe(data => {
        this.tareas = data;
        this.cargando = false;
      }, error => {
        console.error(error);
        this.cargando = false;
      });
  }

  generarReporte(reporte: any, tipo: number) {
    if (tipo === 2) {
      this.apiService.utilService.downloadFileText(`reporte_errores_${reporte.id}.txt`, reporte.erroresEstructura);
      Swal.close();
      return;
    }
    this.apiService.tareasService
      .informeCargue(reporte.id)
      .subscribe(
        response => {
          this.apiService.utilService.downloadFile(response, `reporte_resultado_${reporte.id}`, 'xlsx');
        },
        error => {
          console.log(error);
          Swal.close();
          this.apiService.notifService.error('Error', error);
        }
      );
  }

  entradaCargue(reporte: any, tipo: number) {
    this.apiService.tareasService
      .entradaCargue(reporte.id)
      .subscribe(
        response => {
          this.apiService.utilService.downloadFile(response, `reporte_entrada_${reporte.id}`, 'xlsx');
        },
        error => {
          console.log(error);
          Swal.close();
          this.apiService.notifService.error('Error', error);
        }
      );
  }

  isValido(rol: string) {
    return this.token.isRol(rol);
  }

}
