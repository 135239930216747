import { Component, OnInit } from '@angular/core';
import * as moment from "moment/moment";
import {TIPOS_DOCUMENTOS} from "../../data/datos.constants";
import {Eps} from "../../models/eps.model";
import {Regimen} from "../../models/regimen.model";
import {ApiService} from "../../services/api.service";
import {TokenStorageService} from "../../services/auth/token-storage.service";
import {Title} from "@angular/platform-browser";
import {NAME_APP} from "../../url.constants";
import Swal from "sweetalert2";

@Component({
  selector: 'app-main-reporte-juntas',
  templateUrl: './main-reporte-juntas.component.html',
  styleUrls: ['./main-reporte-juntas.component.scss']
})
export class MainReporteJuntasComponent implements OnInit {

  now = moment();
  momento = moment;
  filtrado: string = "f";
  maxDias: number = 14;

  tipos = TIPOS_DOCUMENTOS;

  fechaInicio: string = this.now.format("YYYY-MM-DD");
  fechaFin: string = this.fechaInicio;
  tipoReporte: number = 0;
  identificacion: string = "";
  tipoId: string = this.tipos[0].value;
  numero: string = "";

  eps: Eps = null;
  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Reporte Juntas Medicas`);
    this.token.validate();
    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
  }

  cambiarFiltro(f: string) {
    this.filtrado = f;
    this.identificacion = "";
    this.numero = "";
  }

  busqueda() {
    Swal.fire({
      title: "Generando archivo ... ",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });

    let nombreReporte = 'JUNTAS_MEDICAS-' + this.eps.identificacion + '-' + this.regimen.codigo;

    switch (this.filtrado) {
      case "f":
        nombreReporte += "-" + this.fechaInicio + "_" + this.fechaFin;
        break;
      case "p":
        nombreReporte += "-" + this.identificacion;
        break;
      case "n":
        nombreReporte += "-" + this.numero;
        break;
    }

    if (this.identificacion.trim().length == 0) {
      this.identificacion = "null";
    }
    if (this.numero.trim().length == 0) {
      this.numero = "null";
    }
    this.apiService.reporteJuntasMedicasService
      .xlsxReporte(
        this.eps.identificacion,
        this.regimen.codigo,
        this.fechaInicio,
        this.fechaFin,
        this.identificacion,
        this.tipoId,
        this.numero
      )
      .subscribe(
        response => {
          this.apiService.utilService.downloadFile(
            response,
            nombreReporte,
            "xlsx"
          );
        },
        error => {
          console.log(error);
          this.apiService.notifService.error("Error", error);
          Swal.close();
        }
      );
  }

}
