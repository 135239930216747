import { Injectable } from '@angular/core';
import {API_REST} from "../url.constants";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class JuntaMedicaService {
  baseUrl = API_REST + "juntas-medicas/";

  constructor(private http: HttpClient) {}

  getPorFecha(nit: string, regimen: string, fecha: string) {
    return this.http.get<any>(
      `${this.baseUrl}byFecha/${nit}/${fecha}/${regimen}`
    );
  }

  getPorPaciente(tipoDoc: string, numDoc: string, nit: string, regimen: string, fecha: string) {
    return this.http.get<any>(
      `${this.baseUrl}byPaciente/${nit}/${fecha}/${regimen}/${tipoDoc}/${numDoc}`
    );
  }

  getPorNumero(nit: string, regimen: string, numero: string) {
    return this.http.get<any>(
      `${this.baseUrl}byNumero/${nit}/${regimen}/${numero}`
    );
  }
}
