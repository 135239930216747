import { DireccionamientoSalida } from "src/app/models/salida/direccionamiento-salida.model";
import { PrincipioActivo } from "./../../../models/mpresc/principio-activo.model";
import { IndicacionUnirs } from "./../../../models/mpresc/indicacion-unirs.model";
import { Medicamento } from "./../../../models/mpresc/medicamento.model";
import {
  Component,
  OnInit,
  Input,
  ElementRef,
  ViewChild,
  Output,
  EventEmitter
} from "@angular/core";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";

@Component({
  selector: "app-modal-medicamento-detalle",
  templateUrl: "./modal-medicamento-detalle.component.html",
  styleUrls: ["./modal-medicamento-detalle.component.scss"]
})
export class ModalMedicamentoDetalleComponent implements OnInit {
  @Input() public medicamento: Medicamento;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public id: string = 'modalMedicamentoDetalle';
  @Output() public showModalPrescripcion = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalPrescripcion.emit(this.prescripcion);
  }
}
