import { Injectable } from '@angular/core';
import {Observable} from 'rxjs';
import {HttpClient, HttpEvent, HttpRequest} from '@angular/common/http';
import {API_REST} from '../url.constants';

@Injectable({
  providedIn: 'root'
})
export class TareasService {

  baseUrl = API_REST + 'cargues/';

  constructor(private http: HttpClient) { }

  registrarCargue(data: any): Observable<HttpEvent<{}>> {
    const req = new HttpRequest('POST', `${this.baseUrl}cargue_proceso`, data, {
      reportProgress: true,
      responseType: 'text'
    });
    return this.http.request(req);
  }

  informeCargue(idCargue: number) {
    return this.http.get(`${this.baseUrl}obtener_reporte/${idCargue}?format=xlsx`, { responseType: 'blob' });
  }

  entradaCargue(idCargue: number) {
    return this.http.get(`${this.baseUrl}obtener_entrada/${idCargue}?format=xlsx`, { responseType: 'blob' });
  }

  listadoCargues(id: any, fechaBusqueda: string) {
    return this.http.get<any>(`${this.baseUrl}listado?id=${id}&fecha=${fechaBusqueda}`)
  }
}
