import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-modal-junta-medica-detalle',
  templateUrl: './modal-junta-medica-detalle.component.html',
  styleUrls: ['./modal-junta-medica-detalle.component.scss']
})
export class ModalJuntaMedicaDetalleComponent implements OnInit {

  @Input() public noDireccionamiento: any;
  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() { }

  ngOnInit() {
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

}
