import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators
} from "@angular/forms";
import * as moment from 'moment';
import { Select2OptionData } from "ng2-select2";
import { Observable } from "rxjs";
import { Eps } from "src/app/models/eps.model";
import { PrescripcionApi } from "src/app/models/mpresc/prescripcion-api.model";
import { Proveedor } from "src/app/models/proveedor.model";
import { Regimen } from "src/app/models/regimen.model";
import { DireccionamientoSalida } from "src/app/models/salida/direccionamiento-salida.model";
import { Tarifa } from "src/app/models/tarifa.model";
import Swal from "sweetalert2";
import { MedicamentoTut } from "./../../../models/mpresc/medicamento-tut.model";
import { Medicamento } from "./../../../models/mpresc/medicamento.model";
import { TutelaApi } from "./../../../models/mpresc/tutela-api.model";
import { TutelaAfiliado } from './../../../models/tutela-afiliado.model';
import { ApiService } from "./../../../services/api.service";
import {ServicioComplementario} from "../../../models/mpresc/servicio-complementario.model";
import {Procedimiento} from "../../../models/mpresc/procedimiento.model";
import {ProductoNutricional} from "../../../models/mpresc/producto-nutricional.model";
import {DispositivoMedico} from "../../../models/mpresc/dispositivo-medico.model";
import {ModalMedicamentoDetalleComponent} from "../modal-medicamento-detalle/modal-medicamento-detalle.component";
import {
  ModalServicioComplementarioDetalleComponent
} from "../modal-servicio-complementario-detalle/modal-servicio-complementario-detalle.component";
import {ModalProcedimientoDetalleComponent} from "../modal-procedimiento-detalle/modal-procedimiento-detalle.component";
import {
  ModalProductoNutricionalDetalleComponent
} from "../modal-producto-nutricional-detalle/modal-producto-nutricional-detalle.component";
import {
  ModalDispositivoMedicoDetalleComponent
} from "../modal-dispositivo-medico-detalle/modal-dispositivo-medico-detalle.component";
import {
  ModalDispositivoMedicoTutDetalleComponent
} from "../modal-dispositivo-medico-tut-detalle/modal-dispositivo-medico-tut-detalle.component";
import {
  ModalProductoNutricionalTutDetalleComponent
} from "../modal-producto-nutricional-tut-detalle/modal-producto-nutricional-tut-detalle.component";
import {
  ModalProcedimientoTutDetalleComponent
} from "../modal-procedimiento-tut-detalle/modal-procedimiento-tut-detalle.component";
import {
  ModalServicioComplementarioTutDetalleComponent
} from "../modal-servicio-complementario-tut-detalle/modal-servicio-complementario-tut-detalle.component";
import {
  ModalMedicamentoTutDetalleComponent
} from "../modal-medicamento-tut-detalle/modal-medicamento-tut-detalle.component";
import {invalidInputGeneral} from "../../../data/datos.constants";

@Component({
  selector: "app-modal-direccionamiento-add",
  templateUrl: "./modal-direccionamiento-add.component.html",
  styleUrls: ["./modal-direccionamiento-add.component.scss"]
})
export class ModalDireccionamientoAddComponent implements OnInit {
  now = moment();
  momento = moment;
  @Input() public salida: DireccionamientoSalida;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public tutela: TutelaApi;
  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public medicamento: Medicamento;
  @Input() public medicamentoTut: MedicamentoTut;
  @Input() public informacion: any;
  @Input() public tarifa: Tarifa;
  @Input() public tutelaAfiliado: TutelaAfiliado;
  @Input() public proveedorSeleccionado: Proveedor = null;
  @Input() public selectedFiles: any;
  @Input() public nameFile: any;
  @Output() public showModalPrescripcion = new EventEmitter();
  @Output() public showModalTutela = new EventEmitter();
  @Output() public showModalBuscarTutelas = new EventEmitter();
  @Output() public showModalBuscarTarifas = new EventEmitter();

  procedimiento: boolean = false;

  cums: any[] = [];
  cargandoCums: boolean = false;
  busquedaCums: boolean = false;

  cumsAbiertos: any[] = [];
  cargandoCumsAbiertos: boolean = false;
  busquedaCumsAbiertos: boolean = false;

  iums: any[] = [];
  cargandoIums: boolean = false;
  busquedaIums: boolean = false;

  cups: any[] = [];
  cargandoCups: boolean = false;
  busquedaCups: boolean = false;

  dcis: any[] = [];
  cargandoDci: boolean = false;
  busquedaDci: boolean = false;

  direccionamientoForm: FormGroup;

  salidaEdit: DireccionamientoSalida = null;

  cumForm: FormGroup;
  iumForm: FormGroup;
  cupForm: FormGroup;
  dciForm: FormGroup;

  cumSelect: any = null;

  filtrado: string = "c";

  proveedor: Proveedor = null;
  enviandoForm: boolean = false;

  idProvSelect: Observable<String> = null;

  numeroFactura = "";

  public proveedoresData: Observable<Array<Select2OptionData>>;
  public selectOptions: Select2Options;

  @ViewChild("modalMedicamentoDir", { static: false })
  modalMedicamento: ModalMedicamentoDetalleComponent;

  @ViewChild("modalServicioComplDir", { static: false })
  modalServicioCompl: ModalServicioComplementarioDetalleComponent;

  @ViewChild("modalProcedimientoDir", { static: false })
  modalProcedimiento: ModalProcedimientoDetalleComponent;

  @ViewChild("modalProductoNutricDir", { static: false })
  modalProductoNutric: ModalProductoNutricionalDetalleComponent;

  @ViewChild("modalDispositivoMedicDir", { static: false })
  modalDispositivoMedic: ModalDispositivoMedicoDetalleComponent;

  @ViewChild("modalMedicamentoTutDir", { static: false })
  modalMedicamentoTut: ModalMedicamentoTutDetalleComponent;

  @ViewChild("modalServicioComplTutDir", { static: false })
  modalServicioComplTut: ModalServicioComplementarioTutDetalleComponent;

  @ViewChild("modalProcedimientoTutDir", { static: false })
  modalProcedimientoTut: ModalProcedimientoTutDetalleComponent;

  @ViewChild("modalProductoNutricTutDir", { static: false })
  modalProductoNutricTut: ModalProductoNutricionalTutDetalleComponent;

  @ViewChild("modalDispositivoMedicTutDir", { static: false })
  modalDispositivoMedicTut: ModalDispositivoMedicoTutDetalleComponent;

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor(private fb: FormBuilder, private apiService: ApiService) {}

  inicializar() {
    this.procedimiento = false;
    this.cums = [];
    this.cargandoCums = false;
    this.busquedaCums = false;
    this.iums = [];
    this.cargandoIums = false;
    this.busquedaIums = false;
    this.cups = [];
    this.cargandoCups = false;
    this.busquedaCups = false;
    this.dcis = [];
    this.cargandoDci = false;
    this.busquedaDci = false;
    this.salidaEdit = null;
    this.cumSelect = null;
    this.filtrado = "c";
    this.enviandoForm = false;
  }

  ngOnInit() {
    if (this.eps) {
      let direccionPac = "";
      if (this.salida.TipoTec == "P") {
        this.procedimiento = true;
        this.filtrado = "u";
      }
      try {
        direccionPac = this.prescripcion.prescripcion.rel.rel.direccion;
      } catch (error) {}
      try {
        direccionPac = this.tutela.tutela.rel.rel.direccion;
      } catch (error) {}

      let completarInfo = {
        FecMaxEnt: this.informacion ? this.informacion.FecMaxEnt : "",
        DirPaciente: this.informacion
          ? this.informacion.DirPaciente
          : direccionPac,
        CantTotAEntregar: this.informacion
          ? this.informacion.CantTotAEntregar
          : "",
        NumeroTutela: this.tutelaAfiliado
          ? this.tutelaAfiliado.numeroTutela
          : "",
        IdTutela: this.tutelaAfiliado
            ? this.tutelaAfiliado.idSoporteTutela
            : "",
        NoEntrega: this.informacion ? this.informacion.NoEntrega : "1",
        NoSubEntrega: this.informacion ? this.informacion.NoSubEntrega : "0",
        CodSerTecAEntregar: this.informacion
          ? this.informacion.CodSerTecAEntregar
          : this.salida.CodSerTecAEntregar,
        DescSerTec: this.informacion
          ? this.informacion.DescSerTec
          : this.salida.DescSerTec,
        DescTarifa: this.tarifa
          ? `$ ${this.getTarifa()}: ${this.tarifa.nombre_tarifa}`
          : "",
        ValorTarifa:
          this.tarifa && this.informacion && this.informacion.CantTotAEntregar
            ? this.tarifa.valor_tarifa *
              parseInt(this.informacion.CantTotAEntregar)
            : 0
      };

      let expediente = this.tarifa ? this.tarifa.nombre_codigo : ''

      this.direccionamientoForm = this.fb.group({
        FecMaxEnt: new FormControl(
          completarInfo.FecMaxEnt,
          Validators.required
        ),
        DirPaciente: new FormControl(
          completarInfo.DirPaciente,
          Validators.required
        ),
        CantTotAEntregar: new FormControl(
          completarInfo.CantTotAEntregar,
          Validators.required
        ),
        NoEntrega: new FormControl(
          completarInfo.NoEntrega,
          Validators.required
        ),
        NoSubEntrega: new FormControl(
          completarInfo.NoSubEntrega,
          Validators.required
        ),
        CodSerTecAEntregar: new FormControl(
          completarInfo.CodSerTecAEntregar,
          Validators.required
        ),
        DescTarifa: new FormControl(completarInfo.DescTarifa),
        NumeroTutela: new FormControl(completarInfo.NumeroTutela),
        IdTutela: new FormControl(completarInfo.IdTutela),
        ValorTarifa: new FormControl(
          completarInfo.ValorTarifa,
          Validators.required
        ),
        DescSerTec: new FormControl(completarInfo.DescSerTec),
      });
      this.cumForm = this.fb.group({
        producto: new FormControl(""),
        principioactivo: new FormControl(""),
        expediente: new FormControl(expediente)
      });
      this.iumForm = this.fb.group({
        producto: new FormControl(""),
        principioactivo: new FormControl(""),
        ium: new FormControl(expediente)
      });
      this.cupForm = this.fb.group({
        nombre: new FormControl(expediente)
      });
      this.dciForm = this.fb.group({
        nombre: new FormControl(expediente)
      });
    }

    this.selectOptions = {
      width: "100%",
      allowClear: true,
      dropdownParent: $(document.getElementById('contenedor-search-proveedor'))
    };

    this.proveedoresData = this.apiService.proveedoresService.getDynamicList(
      this.proveedorSeleccionado
    );
  }

  limpiarTutela() {
    this.direccionamientoForm.patchValue({
      NumeroTutela: "",
      IdTutela: "",
    });
  }

  disabledEnvio() {
    let form = !this.direccionamientoForm.valid;
    let validarTarifa =
      this.direccionamientoForm.get("CodSerTecAEntregar") != null &&
      this.direccionamientoForm.get("CodSerTecAEntregar").value == "152" &&
      this.salida.TipoTec == "S";

    let valTarifa = 0;
    try {
      valTarifa = Number.parseInt(
        this.direccionamientoForm.get("ValorTarifa").value
      );
    } catch (error) {}

    let valDescTarifa =
      this.direccionamientoForm.get("DescTarifa").value.length == 0;

    return form || (validarTarifa ? valTarifa <= 0 : valDescTarifa);
  }

  getTarifa() {
    return this.tarifa.valor_tarifa
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  public changeProv(e: any): void {
    try {
      let prov: Proveedor = Object.assign({}, JSON.parse(e.value));
      this.proveedor = prov;
    } catch (error) {
    }
  }

  showModal() {
    this.inicializar();
    this.ngOnInit();
  }

  hideModal(open: boolean = true) {
    this.modalClose.nativeElement.click();
    if (open) {
      if (this.prescripcion) {
        this.showModalPrescripcion.emit(this.prescripcion);
      }
      if (this.tutela) {
        this.showModalTutela.emit(this.tutela);
      }
    }
  }

  detalleMedicamento(tut = false) {
    if (tut) {
      this.modalMedicamentoTut.medicamento = this.salida.data;
    } else {
      this.modalMedicamento.medicamento = this.salida.data;
    }
  }

  detalleServicioCompl(tut = false) {
    if (tut) {
      this.modalServicioComplTut.servicioCompl = this.salida.data;
    } else {
      this.modalServicioCompl.servicioCompl = this.salida.data;
    }
  }

  detalleProcedimiento(tut = false) {
    if (tut) {
      this.modalProcedimientoTut.procedimiento = this.salida.data;
    } else {
      this.modalProcedimiento.procedimiento = this.salida.data;
    }
  }

  detalleProductoNutri(tut = false) {
    if (tut) {
      this.modalProductoNutricTut.productoNutr = this.salida.data;
    } else {
      this.modalProductoNutric.productoNutr = this.salida.data;
    }
  }

  detalleDispositivoMedic(tut = false) {
    if (tut) {
      this.modalDispositivoMedicTut.dispositivo = this.salida.data;
    } else {
      this.modalDispositivoMedic.dispositivo = this.salida.data;
    }
  }

  cambioCantidad(event: any) {
    try {
      let cantidad = parseInt(event.target.value);
      if (!isNaN(cantidad) && this.tarifa) {
        this.direccionamientoForm.patchValue({
          ValorTarifa: this.tarifa ? this.tarifa.valor_tarifa * cantidad : 0
        });
      }
    } catch (error) {
      this.direccionamientoForm.patchValue({
        CantTotAEntregar: 0
      })
    }
  }

  public seleccionarCum(cum: any) {
    this.cumSelect = cum;
    this.direccionamientoForm.patchValue({
      CodSerTecAEntregar: cum.codigo,
      DescSerTec: cum.nombre
    });
    this.busquedaCums = false;
    this.cums = [];
  }

  public seleccionarCumAbierto(cum: any) {
    this.cumSelect = cum;
    this.direccionamientoForm.patchValue({
      CodSerTecAEntregar: cum.expedientecum + "-" + cum.consecutivocum,
      DescSerTec: cum.producto
    });
    this.busquedaCumsAbiertos = false;
    this.cumsAbiertos = [];
  }

  public seleccionarIum(ium: any) {
    this.direccionamientoForm.patchValue({
      CodSerTecAEntregar: ium.ium,
      DescSerTec: ium.nombre_comercial_
    });
    this.busquedaIums = false;
    this.iums = [];
  }

  public seleccionarCup(cup: any) {
    this.direccionamientoForm.patchValue({
      CodSerTecAEntregar: cup.codCups,
      DescSerTec: cup.descCups
    });
    this.busquedaCups = false;
    this.cups = [];
  }

  public seleccionarDci(cup: any) {
    this.direccionamientoForm.patchValue({
      CodSerTecAEntregar: cup.codigo,
      DescSerTec: cup.descripcion
    });
    this.busquedaDci = false;
    this.dcis = [];
  }

  buscarCum() {
    this.cums = [];
    this.cargandoCums = true;
    this.busquedaCums = true;
    let { producto, principioactivo, expediente } = this.cumForm.value;
    this.apiService.codigosMipresService
      .getCum(
        producto,
        expediente,
        principioactivo
      )
      .subscribe(data => {
        this.cums = data;
        this.cargandoCums = false;
      },error => {
        this.cargandoCums = false;
        console.log(error);
      })
  }

  buscarCumAbiertos(tipo: number) {
    this.cums = [];
    this.cargandoCumsAbiertos = true;
    this.busquedaCumsAbiertos = true;
    let { producto, principioactivo, expediente } = this.cumForm.value;
    this.apiService.cumService
      .getByProductoOrPrincipioActivoOrExpediente(
        tipo,producto,
        principioactivo,
        expediente
      )
      .then(data => {
        this.cumsAbiertos = data.data;
        this.cargandoCumsAbiertos = false;
      },error => {
        this.cargandoCumsAbiertos = false;
        console.log(error);
      })
  }

  buscarIum() {
    this.iums = [];
    this.cargandoIums = true;
    this.busquedaIums = true;
    let { producto, principioactivo, ium } = this.iumForm.value;
    this.apiService.iumService
      .getByNombreComercialOrPrincipioActivoOrIum(
        producto,
        principioactivo,
        ium
      )
      .then(data => {
        this.iums = data.data;
        this.cargandoIums = false;
      })
      .catch(error => {
        this.cargandoIums = false;
        console.log(error);
      });
  }

  buscarCup() {
    this.cups = [];
    this.cargandoCups = true;
    this.busquedaCups = true;
    let { nombre } = this.cupForm.value;
    this.apiService.cupsService.getCupsByNombre(nombre).subscribe(
      data => {
        this.cups = data.cups;
        this.cargandoCups = false;
      },
      error => {
        this.cargandoCups = false;
        console.log(error);
      }
    );
  }

  buscarDci() {
    this.dcis = [];
    this.cargandoDci = true;
    this.busquedaDci = true;
    let { nombre } = this.dciForm.value;
    this.apiService.codigosMipresService.getDciByNombre(nombre).subscribe(
      data => {
        this.dcis = data;
        this.cargandoDci = false;
      },
      error => {
        this.cargandoDci = false;
        console.log(error);
      }
    );
  }

  buscarTarifas() {
    this.showModalBuscarTarifas.emit({
      presc: this.prescripcion,
      tut: this.tutela,
      prov: this.proveedor,
      direccionamiento: {
        salida: this.salida,
        presc: this.prescripcion,
        tut: this.tutela,
        medicamentoTut: this.medicamentoTut,
        medicamento: this.medicamento,
        informacion: this.direccionamientoForm.value,
        tarifa: this.tarifa,
        tutelaAfiliado: this.tutelaAfiliado,
        selectedFiles: this.selectedFiles,
        nameFile: this.nameFile
      }
    });
  }

  buscarTutelasAfiliado() {
    this.showModalBuscarTutelas.emit({
      presc: this.prescripcion,
      tut: this.tutela,
      prov: this.proveedor,
      direccionamiento: {
        salida: this.salida,
        presc: this.prescripcion,
        tut: this.tutela,
        medicamentoTut: this.medicamentoTut,
        medicamento: this.medicamento,
        informacion: this.direccionamientoForm.value,
        tarifa: this.tarifa,
        tutelaAfiliado: this.tutelaAfiliado,
        selectedFiles: this.selectedFiles,
        nameFile: this.nameFile
      }
    });
  }

  handleFileInput(event: any) {
    this.selectedFiles = event.target.files;
    this.nameFile = this.selectedFiles.item(0).name;
  }

  onSubmit() {
    this.enviandoForm = true;
    this.salidaEdit = Object.assign({}, this.direccionamientoForm.value);

    this.salidaEdit.ConTec = this.salida.ConTec;
    this.salidaEdit.TipoTec = this.salida.TipoTec;
    this.salidaEdit.NoPrescripcion = this.salida.NoPrescripcion;

    this.salidaEdit.TipoIDPaciente = this.salida.TipoIDPaciente;
    this.salidaEdit.NoIDPaciente = this.salida.NoIDPaciente;
    this.salidaEdit.DiagPrinc = this.salida.DiagPrinc;

    this.salidaEdit.TipoIDProv = this.proveedor.tipoId;
    this.salidaEdit.NoIDProv = this.proveedor.identificacion;
    this.salidaEdit.CodMunEnt = this.proveedor.codMunicipio;
    this.salidaEdit.IdPrestador = this.proveedor.id;
    if (this.tarifa) {
      this.salidaEdit.IdTarifa = this.tarifa.id_tarifa;
    }

    if (this.prescripcion) {
      this.salidaEdit.IdentificacionPrescriptor = this.prescripcion.prescripcion.NroIDIPS;
      this.salidaEdit.CodHabPrescriptor = this.prescripcion.prescripcion.CodHabIPS;
      let ambito = ''
      if (this.prescripcion.prescripcion.CodAmbAte == 11 || this.prescripcion.prescripcion.CodAmbAte == 12) {
        ambito = 'A';
      }
      if (this.prescripcion.prescripcion.CodAmbAte == 21) {
        ambito = 'D';
      }
      if (this.prescripcion.prescripcion.CodAmbAte == 22) {
        ambito = 'H';
      }
      if (this.prescripcion.prescripcion.CodAmbAte == 30) {
        ambito = 'U';
      }
      this.salidaEdit.Ambito = ambito
      this.salidaEdit['mprescripcion'] = new Blob([JSON.stringify(this.prescripcion)], {type : "application/json"});
    }
    if (this.tutela) {
      this.salidaEdit.IdentificacionPrescriptor = this.tutela.tutela.NroIDEPS;
      this.salidaEdit['mtutela'] = new Blob([JSON.stringify(this.tutela)], {type : "application/json"});
    }

    let esTutela = this.prescripcion ? false : this.tutela ? true : false;

    if (!this.salidaEdit.IdTarifa && !(this.salidaEdit.CodSerTecAEntregar == '152' && this.salida.TipoTec == 'S')) {
      Swal.fire({
        title: 'Error',
        text: 'Tarifa no seleccionada.',
        type: 'error'
      })
      this.enviandoForm = false;
      return;
    }
    if (!this.momento(this.salidaEdit.FecMaxEnt).isValid()) {
      Swal.fire({
        title: 'Error',
        text: 'No puede realizar el direccionamiento, la fecha máxima de entrega es invalida.',
        type: 'error'
      })
      this.enviandoForm = false;
      return;
    }

    let fecha_maxima = this.momento(this.salidaEdit.FecMaxEnt)

    if (fecha_maxima.isBefore(this.momento().format('YYYY-MM-DD'))){
      Swal.fire({
        title: 'Error',
        text: 'La fecha máxima no puede ser inferior a hoy.',
        type: 'error'
      })
      this.enviandoForm = false;
      return;
    }
    if (!this.validarMarcarMedicamento()) {
      this.salidaEdit.CodSerTecAEntregar = ' '
    }
    let formulario: any = this.salidaEdit

    let archivo =
      this.selectedFiles && this.selectedFiles.length != 0
        ? this.selectedFiles.item(0)
        : null;
    let form_data = new FormData();
    for (let key in this.salidaEdit) {
      form_data.append(key, this.salidaEdit[key]);
    }
    if (archivo) {
      form_data.append('mfile', archivo)
    }
    formulario = form_data;
    this.apiService.direccionamientosService
      .enviar(
        esTutela,
        this.eps.identificacion,
        this.regimen.codigo,
        formulario
      )
      .subscribe(
        data => {
          this.enviandoForm = false;
          Swal.fire({
            title: "Hecho",
            type: "success",
            text: "El direccionamiento se ha realizado con exito"
          });
          if (this.prescripcion) {
            this.apiService.utilService.realizadoAuditoriaPrescDetalle(this.prescripcion, this.salida.TipoTec, this.salida.ConTec);
          } else {
            this.apiService.utilService.realizadoAuditoriaTutelaDetalle(this.tutela, this.salida.TipoTec, this.salida.ConTec);
          }
          this.hideModal();
          this.pdfDireccionamiento(
            this.salidaEdit.NoPrescripcion,
            data.identificadores[0].IdDireccionamiento
          );
        },
        error => {
          this.enviandoForm = false;
          console.log(error);
          this.apiService.notifService.error("Error", error);
        }
    );
  }

  pdfDireccionamiento(numPrescripcion: string, IDDireccionamiento: number) {
    Swal.fire({
      title: "Generando archivo ... ",
      allowOutsideClick: false,
      onBeforeOpen: () => Swal.showLoading()
    });
    if (this.tutela) {
      this.apiService.tutelasService
        .getPorIdentificadorPdf(
          this.eps.identificacion,
          this.regimen.codigo,
          numPrescripcion,
          IDDireccionamiento
        )
        .subscribe(
          response => {
            Swal.close();
            this.apiService.utilService.downloadFile(
              response,
              "Direccionamiento - " + IDDireccionamiento,
              "pdf"
            );
          },
          error => {
            Swal.close();
            console.log(error);
            this.apiService.notifService.error("Error", error);
          }
        );
    } else {
      this.apiService.prescripcionesService
        .getPorIdentificadorPdf(
          this.eps.identificacion,
          this.regimen.codigo,
          numPrescripcion,
          IDDireccionamiento
        )
        .subscribe(
          response => {
            Swal.close();
            this.apiService.utilService.downloadFile(
              response,
              "Direccionamiento - " + IDDireccionamiento,
              "pdf"
            );
          },
          error => {
            Swal.close();
            console.log(error);
            this.apiService.notifService.error("Error", error);
          }
        );
    }
  }

  validarMarcarMedicamento() {
    if (this.medicamentoTut) {
      return this.medicamentoTut.TipoMed != 3
    }
    if (this.medicamento) {
      return this.medicamento.TipoMed != 3
    }
    return true;
  }

  getDescTipo(tipo: string) {
    switch (tipo) {
      case "M":
        return "Medicamento";
      case "D":
        return "Dispositivo";
      case "P":
        return "Procedimiento";
      case "N":
        return "Producto nutricional";
      case "S":
        return "Servicio complementario";
      default:
        return "";
    }
  }

  protected readonly invalidInputGeneral = invalidInputGeneral;
}
