import { TutelasAfiliadoService } from './tutelas-afiliado.service';
import { CupsService } from './cups.service';
import { IumService } from './ium.service';
import { TarifasService } from './tarifas.service';
import { CodigosMipresService } from './codigos-mipres.service';
import { DatosFacturadosService } from './datos-facturados.service';
import { FacturacionesService } from './facturaciones.service';
import { NoDireccionamientosService } from './no-direccionamientos.service';
import { TutelasService } from './tutelas.service';
import { AuditoriaService } from './auditoria.service';
import { CumService } from './cum.service';
import { DireccionamientosService } from "./direccionamientos.service";
import { MunicipiosService } from "./municipios.service";
import { ProveedoresService } from "./proveedores.service";
import { TokenService } from "./token.service";
import { UsuarioService } from "./usuario.service";
import { UtilService } from "./util.service";
import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { NotificacionService } from "./notificacion.service";
import { EpsService } from "./eps.service";
import { PrescripcionesService } from "./prescripciones.service";
import { ReportesEntregasService } from './reportes-entregas.service';
import { SuministrosService } from './suministros.service';
import { ReporteDireccionamientosService } from './reporte-direccionamientos.service';
import { InformeAutorizacionesService } from './informe-autorizaciones.service';
import { TarifaMensualService } from './tarifa-mensual.service';
import { AfiliadoService } from './afiliado.service';
import { ValidadorPrescripcionesService } from './validador-prescripciones.service';
import {TareasService} from './tareas.service';
import {ReporteJuntasMedicasService} from "./reporte-juntas-medicas.service";
import {JuntaMedicaService} from "./junta-medica.service";

@Injectable({
  providedIn: "root"
})
export class ApiService {
  usuarioService: UsuarioService;
  afiliadoService: AfiliadoService;
  epsService: EpsService;
  tokenService: TokenService;
  prescripcionesService: PrescripcionesService;
  proveedoresService: ProveedoresService;
  municipiosService: MunicipiosService;
  direccionamientosService: DireccionamientosService;
  cumService: CumService;
  auditoriaService: AuditoriaService;
  tutelasService: TutelasService;
  noDireccionamientosService: NoDireccionamientosService;
  reportesEntregasService : ReportesEntregasService;
  suministrosService: SuministrosService;
  facturacionesService: FacturacionesService;
  datosFacturadosService: DatosFacturadosService;
  codigosMipresService: CodigosMipresService;
  tarifasService: TarifasService;
  tutelasAfiliadoService: TutelasAfiliadoService;
  reporteDireccionamientosService: ReporteDireccionamientosService;
  reporteJuntasMedicasService: ReporteJuntasMedicasService;
  informeAutorizacionesService: InformeAutorizacionesService;
  tarifaMensualService: TarifaMensualService;
  juntaMedicaService: JuntaMedicaService;
  iumService:IumService;
  cupsService: CupsService;
  tareasService: TareasService;
  validadorPrescripcionesService: ValidadorPrescripcionesService;

  constructor(
    private http: HttpClient,
    public notifService: NotificacionService,
    public utilService: UtilService
  ) {
    this.usuarioService = new UsuarioService(http);
    this.afiliadoService = new AfiliadoService(http);
    this.epsService = new EpsService(http);
    this.tokenService = new TokenService(http);
    this.prescripcionesService = new PrescripcionesService(http);
    this.proveedoresService = new ProveedoresService(http);
    this.municipiosService = new MunicipiosService(http);
    this.direccionamientosService = new DireccionamientosService(http);
    this.auditoriaService = new AuditoriaService(http);
    this.tutelasService = new TutelasService(http);
    this.noDireccionamientosService = new NoDireccionamientosService(http);
    this.reportesEntregasService = new ReportesEntregasService(http);
    this.suministrosService = new SuministrosService(http);
    this.facturacionesService = new FacturacionesService(http);
    this.datosFacturadosService = new DatosFacturadosService(http);
    this.codigosMipresService = new CodigosMipresService(http);
    this.tarifasService = new TarifasService(http);
    this.tutelasAfiliadoService = new TutelasAfiliadoService(http);
    this.reporteDireccionamientosService = new ReporteDireccionamientosService(http);
    this.cupsService = new CupsService(http);
    this.informeAutorizacionesService = new InformeAutorizacionesService(http);
    this.tarifaMensualService = new TarifaMensualService(http);
    this.validadorPrescripcionesService = new ValidadorPrescripcionesService(http);
    this.tareasService = new TareasService(http);
    this.reporteJuntasMedicasService = new ReporteJuntasMedicasService(http);
    this.juntaMedicaService = new JuntaMedicaService(http);
    this.iumService = new IumService();
    this.cumService = new CumService();
  }
}
