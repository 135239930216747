import { Component, OnInit } from "@angular/core";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";
import { ReporteEntrega } from "./../../models/msumin/reporte-entrega.model";
import { ApiService } from "./../../services/api.service";
import { NAME_APP } from "./../../url.constants";

import { Title } from "@angular/platform-browser";
import * as moment from "moment";
import { Eps } from "src/app/models/eps.model";
import { Regimen } from "src/app/models/regimen.model";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";

@Component({
  selector: "app-main-reportes-entregas",
  templateUrl: "./main-reportes-entregas.component.html",
  styleUrls: ["./main-reportes-entregas.component.scss"]
})
export class MainReportesEntregasComponent implements OnInit {
  now = moment();
  filtrado: string = "f";
  fecha: string = this.now.format("YYYY-MM-DD");
  fechaAfiliado: string = this.now.format("YYYY-MM-DD");
  tipoDocumento: string = "CC";
  documento: string;
  prescripcion: string;
  primera: boolean = false;
  cargando: boolean = false;

  reportes: ReporteEntrega[] = [];

  tipos = TIPOS_DOCUMENTOS;

  eps: Eps = null;

  regimen: Regimen = null;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - Reportes de entrega`);
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
    this.apiService.utilService.buscarEnTabla();
  }

  cambiarFiltro(f: string) {
    this.reportes = [];
    this.filtrado = f;
  }

  busqueda(tipo: number) {
    this.reportes = [];
    this.cargando = true;
    if (tipo == 1) {
      // GENERAR TABLA
      switch (this.filtrado) {
        case "f":
          this.apiService.reportesEntregasService
            .byFecha(this.eps.identificacion, this.regimen.codigo, this.fecha)
            .subscribe(
              data => {
                this.cargando = false;
                this.primera = true;
                this.reportes = [];
                if (data.reportes) {
                  this.reportes = data.reportes;
                }
              },
              error => {
                this.apiService.notifService.error("Error", error);
                console.error(error);
                this.primera = true;
                this.cargando = false;
              }
            );

          break;
        case "a":
          this.apiService.reportesEntregasService
            .byFechaPaciente(
              this.tipoDocumento,
              this.documento,
              this.eps.identificacion,
              this.regimen.codigo,
              this.fechaAfiliado
            )
            .subscribe(
              data => {
                this.cargando = false;
                this.primera = true;
                this.reportes = [];
                if (data.reportes) {
                  this.reportes = data.reportes;
                }
              },
              error => {
                this.apiService.notifService.error("Error", error);
                console.error(error);
                this.primera = true;
                this.cargando = false;
              }
            );

          break;
        case "p":
          this.apiService.reportesEntregasService
            .byNumero(
              this.eps.identificacion,
              this.regimen.codigo,
              this.prescripcion
            )
            .subscribe(
              data => {
                this.cargando = false;
                this.primera = true;
                this.reportes = [];
                if (data.reportes) {
                  this.reportes = data.reportes;
                }
              },
              error => {
                this.apiService.notifService.error("Error", error);
                console.error(error);
                this.primera = true;
                this.cargando = false;
              }
            );
          break;
        default:
          break;
      }
    } else {
      // GENERAR EXCEL
      switch (this.filtrado) {
        case "f":
          this.apiService.reportesEntregasService
            .byFechaExcel(
              this.eps.identificacion,
              this.regimen.codigo,
              this.fecha
            )
            .subscribe(
              response => {
                this.apiService.utilService.downloadFile(
                  response,
                  "Reportes entregas",
                  "xlsx"
                );
                this.cargando = false;
                this.primera = true;
              },
              error => {
                this.cargando = false;
                this.primera = true;
                console.error(error);
                this.apiService.notifService.error("Error", error);
              }
            );

          break;
        case "a":
          this.apiService.reportesEntregasService
            .byFechaPaciente(
              this.eps.identificacion,
              this.regimen.codigo,
              this.fecha,
              this.documento,
              this.tipoDocumento
            )
            .subscribe(
              response => {
                this.apiService.utilService.downloadFile(
                  response,
                  "Reportes entregas",
                  "xlsx"
                );
                this.cargando = false;
                this.primera = true;
              },
              error => {
                this.cargando = false;
                this.primera = true;
                console.error(error);
                this.apiService.notifService.error("Error", error);
              }
            );

          break;
        case "p":
          this.apiService.reportesEntregasService
            .byNumeroExcel(
              this.eps.identificacion,
              this.regimen.codigo,
              this.prescripcion
            )
            .subscribe(
              response => {
                this.apiService.utilService.downloadFile(
                  response,
                  "Reportes entregas",
                  "xlsx"
                );
                this.cargando = false;
                this.primera = true;
              },
              error => {
                this.cargando = false;
                this.primera = true;
                console.error(error);
                this.apiService.notifService.error("Error", error);
              }
            );
          break;
        default:
          break;
      }
    }
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
