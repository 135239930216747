import { Injectable } from '@angular/core';
import {API_REST} from "../url.constants";
import {HttpClient} from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ReporteJuntasMedicasService {
  baseUrl = API_REST;

  constructor(private http: HttpClient) {}

  xlsxReporte(
    nit: string,
    regimen: string,
    inicio: string,
    fin: string,
    identificacion: string,
    tipoId: string,
    numero: string
  ) {
    return this.http.get(
      `${this.baseUrl}junta-medica/xlsx-all/${nit}/${regimen}/${inicio}/${fin}/${tipoId}/${identificacion}/${numero}?format=xlsx`,
      { responseType: "blob" }
    );
  }

}
