import { Component, OnInit, Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';
import { ServicioComplementarioTut } from 'src/app/models/mpresc/servicio-complemento-tut.model';

@Component({
  selector: 'app-modal-servicio-complementario-tut-detalle',
  templateUrl: './modal-servicio-complementario-tut-detalle.component.html',
  styleUrls: ['./modal-servicio-complementario-tut-detalle.component.scss']
})
export class ModalServicioComplementarioTutDetalleComponent implements OnInit {
  @Input() public servicioCompl: ServicioComplementarioTut;
  @Input() public tutela: TutelaApi;
  @Input() public id: string = 'modalServicioTutDetalle';
  @Output() public showModalTutela = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalTutela.emit(this.tutela);
  }

}
