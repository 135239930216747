import { DispositivoMedicoTut } from './../../../models/mpresc/dispositivo-medico-tut.model';
import { Input, Output, ViewChild, ElementRef, EventEmitter } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { TutelaApi } from 'src/app/models/mpresc/tutela-api.model';

@Component({
  selector: 'app-modal-dispositivo-medico-tut-detalle',
  templateUrl: './modal-dispositivo-medico-tut-detalle.component.html',
  styleUrls: ['./modal-dispositivo-medico-tut-detalle.component.scss']
})
export class ModalDispositivoMedicoTutDetalleComponent implements OnInit {
  @Input() public dispositivo: DispositivoMedicoTut;
  @Input() public tutela: TutelaApi;
  @Input() public id: string = 'modalDispositivoMedicTutDetalle';
  @Output() public showModalTutela = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalTutela.emit(this.tutela);
  }

}
