import { PrescripcionApi } from 'src/app/models/mpresc/prescripcion-api.model';
import { Procedimiento } from './../../../models/mpresc/procedimiento.model';
import { Component, OnInit, EventEmitter, ElementRef, ViewChild, Output, Input } from '@angular/core';

@Component({
  selector: 'app-modal-procedimiento-detalle',
  templateUrl: './modal-procedimiento-detalle.component.html',
  styleUrls: ['./modal-procedimiento-detalle.component.scss']
})
export class ModalProcedimientoDetalleComponent implements OnInit {
  @Input() public procedimiento: Procedimiento;
  @Input() public prescripcion: PrescripcionApi;
  @Input() public id: string = 'modalProcedimientoDetalle';
  @Output() public showModalPrescripcion = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  constructor() {}

  ngOnInit() {}

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
    this.showModalPrescripcion.emit(this.prescripcion);
  }

}
