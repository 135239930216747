export class DireccionamientoSalida {
  NoPrescripcion: string;
  TipoTec: string;
  ConTec: number;
  TipoIDPaciente: string;
  NoIDPaciente: string;
  NoEntrega: number;
  NoSubEntrega: number;
  TipoIDProv: string;
  NoIDProv: string;
  CodMunEnt: string;
  FecMaxEnt: string;
  CantTotAEntregar: string;
  DirPaciente: string;
  CodSerTecAEntregar: string;

  IdTutela: number;
  IdTarifa: number;
  NumeroTutela: string;
  ValorTarifa: number;
	IdPrestador: number;
	IdentificacionPrescriptor: string;
	CodHabPrescriptor: string;
	DiagPrinc: string;
  Ambito:string;
  DescSerTec:string;

  data: any;

  // prescripcion:any;
  // tutela:any;
}
