import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {Eps} from "../../../models/eps.model";
import {Regimen} from "../../../models/regimen.model";
import {ApiService} from "../../../services/api.service";

@Component({
  selector: 'app-modal-juntas-medicas-detalle',
  templateUrl: './modal-juntas-medicas-detalle.component.html',
  styleUrls: ['./modal-juntas-medicas-detalle.component.scss']
})
export class ModalJuntasMedicasDetalleComponent implements OnInit {

  @Input() public eps: Eps;
  @Input() public regimen: Regimen;
  @Input() public prescripcion: string;
  @Input() public tutela: boolean;
  @Output() public showModalReporteEntrega = new EventEmitter();

  @ViewChild("modalClose", { static: false }) modalClose: ElementRef;

  reportes: any[];
  cargando: boolean = false;

  constructor(private apiService: ApiService) {}

  ngOnInit() {
    this.cargarInformacion();
  }

  public cargarInformacion() {
    if (this.prescripcion) {
      this.reportes = [];
      this.cargando = true;
      this.apiService.juntaMedicaService
        .getPorNumero(
          this.eps.identificacion,
          this.regimen.codigo,
          this.prescripcion
        )
        .subscribe(
          data => {
            this.reportes = data;
            this.cargando = false;
          },
          error => {
            this.apiService.notifService.error("Error", error);
            this.cargando = false;
          }
        );
    }
  }

  showModal() {
    this.ngOnInit();
  }

  hideModal() {
    this.modalClose.nativeElement.click();
  }

  detalleReporte(rep: any) {
    this.showModalReporteEntrega.emit({ rep: rep, presc: this.prescripcion});
  }

}
