import { Component, ElementRef, OnInit, ViewChild } from "@angular/core";
import { Title } from "@angular/platform-browser";
import * as moment from "moment";
import { Eps } from "src/app/models/eps.model";
import { NoDireccionamiento } from "src/app/models/msumin/no-direccionamiento-model";
import { Regimen } from "src/app/models/regimen.model";
import { ApiService } from "src/app/services/api.service";
import { TokenStorageService } from "src/app/services/auth/token-storage.service";
import { NAME_APP } from "src/app/url.constants";
import { ModalNoDireccionamientoDetalleComponent } from "../modals/modal-no-direccionamiento-detalle/modal-no-direccionamiento-detalle.component";
import { TIPOS_DOCUMENTOS } from "./../../data/datos.constants";

@Component({
  selector: "app-main-no-direccionamientos",
  templateUrl: "./main-no-direccionamientos.component.html",
  styleUrls: ["./main-no-direccionamientos.component.scss"]
})
export class MainNoDireccionamientosComponent implements OnInit {
  now = moment();
  filtrado: string = "f";
  fecha: string = this.now.format("YYYY-MM-DD");
  fechaAfiliado: string = this.now.format("YYYY-MM-DD");
  tipoDocumento: string = "CC";
  documento: string;
  prescripcion: string;
  primera: boolean = false;
  cargando: boolean = false;

  noDirs: NoDireccionamiento[] = [];

  tipos = TIPOS_DOCUMENTOS;

  eps: Eps = null;

  regimen: Regimen = null;

  @ViewChild("modalNoDireccionamientoDetalle", { static: false })
  modalNoDireccionamientoDetalle: ModalNoDireccionamientoDetalleComponent;

  @ViewChild("showModalNoDireccionamiento", { static: false })
  showModalNoDireccionamiento: ElementRef;

  constructor(
    private apiService: ApiService,
    private token: TokenStorageService,
    private titleService: Title
  ) {}

  ngOnInit() {
    this.titleService.setTitle(`${NAME_APP} - No Direccionamientos`);
    this.token.validate();

    this.apiService.epsService.getEps().subscribe(data => {
      if (data.eps) {
        this.eps = data.eps;
        this.regimen = this.eps.regimenes ? this.eps.regimenes[0] : null;
      }
    });
    this.apiService.utilService.buscarEnTabla();
  }

  cambiarFiltro(f: string) {
    this.noDirs = [];
    this.filtrado = f;
  }

  busqueda() {
    this.noDirs = [];
    this.cargando = true;
    // GENERAR TABLA
    switch (this.filtrado) {
      case "f":
        this.apiService.noDireccionamientosService
          .getByFecha(this.eps.identificacion, this.regimen.codigo, this.fecha)
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.noDirs = [];
              if (data.noDireccionamientos) {
                this.noDirs = data.noDireccionamientos;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "a":
        this.apiService.noDireccionamientosService
          .getByPacienteAndFecha(
            this.tipoDocumento,
            this.documento,
            this.eps.identificacion,
            this.regimen.codigo,
            this.fechaAfiliado
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.noDirs = [];
              if (data.noDireccionamientos) {
                this.noDirs = data.noDireccionamientos;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );

        break;
      case "p":
        this.apiService.noDireccionamientosService
          .getPrescripcion(
            this.eps.identificacion,
            this.regimen.codigo,
            this.prescripcion
          )
          .subscribe(
            data => {
              this.cargando = false;
              this.primera = true;
              this.noDirs = [];
              if (data.noDireccionamientos) {
                this.noDirs = data.noDireccionamientos;
              }
            },
            error => {
              this.apiService.notifService.error("Error", error);
              console.error(error);
              this.primera = true;
              this.cargando = false;
            }
          );
        break;
      default:
        break;
    }
  }

  detalle(noDir: NoDireccionamiento) {
    this.modalNoDireccionamientoDetalle.noDireccionamiento = noDir;
    this.modalNoDireccionamientoDetalle.eps = this.eps;
    this.modalNoDireccionamientoDetalle.regimen = this.regimen;
    this.showModalNoDireccionamiento.nativeElement.click();
    this.modalNoDireccionamientoDetalle.showModal();
  }

  updateDir(data: any) {
    for (var i in this.noDirs) {
      let dir = this.noDirs[i];
      if (dir.IDNODireccionamiento == data.idNoDireccionamiento) {
        this.noDirs[i].rel.noDir = data;
      }
    }
  }

  public isValido(rol: string) {
    return this.token.isRol(rol);
  }
}
