import { Injectable } from '@angular/core';
import Swal from 'sweetalert2';
import {PrescripcionApi} from "../models/mpresc/prescripcion-api.model";
import {TutelaApi} from "../models/mpresc/tutela-api.model";

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  constructor(){}

  stringEnumToKeyValue(stringEnum: string) {
    const keyValue = [];
    const keys = Object.keys(stringEnum).filter((value, index) => {
      value = value;
      return !(index % 2);
    });

    for (const k of keys) {
      keyValue.push({ key: k, value: stringEnum[k] });
    }

    return keyValue;
  }

  downloadFile(data: any, nombre: string, format: string) {
    var url = window.URL.createObjectURL(new Blob([data]));

    var a = document.createElement('a');
    document.body.appendChild(a);
    a.setAttribute('style', 'display: none');
    a.href = url;
    a.download = `${nombre}.${format}`;
    a.click();
    window.URL.revokeObjectURL(url);
    a.remove();
    Swal.close();
  }

  downloadFileText(filename, text) {
    let element = document.createElement('a');
    element.setAttribute('href', 'data:text/plain;charset=utf-8,' + encodeURIComponent(text));
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  }


  buscarEnTabla(query = "table.data-table") {
    let tables = $(query);
    let container_id = ".table-responsive";
    let table;
    let item;
    let container;
    tables.each(function() {
      table = $(this);
      container = $(table.closest(container_id));
      if (container.find(".container-filtro-table").length === 0) {
        var input_box = $("<div>", {class: 'main-end container-filtro-table'});
        var input_item = $("<input>", {type: 'text', class: 'table-search-input', placeholder: 'Buscar'});
        input_box.append(input_item);
        container.prepend(input_box);
        input_item.on('keyup', function() {
          item = $(this);
          buscarTablaFila($(item.closest(container_id)).find(query), item.val().toUpperCase());
        });
      } else if (container.find(".table-search-input").length === 0) {
        var input_box = $(container).find(".container-filtro-table");
        var input_item = $("<input>", {type: 'text', class: 'table-search-input', placeholder: 'Buscar'});
        input_box.append(input_item);
        input_item.on('keyup', function() {
          item = $(this);
          buscarTablaFila($(item.closest(container_id)).find(query), item.val().toUpperCase());
        });
      }
    });

    const buscarTablaFila = (table, filter) => {
      var tr, tds, td;
      tr = table.find("tbody tr");
      var cont;
      for (var i = 0; i < tr.length; i++) {
        cont = 0;
        tds = $(tr[i]).find("td");
        for (var j = 0; j < tds.length; j++) {
          td = $(tds[j]);
          if (td.html().trim().toUpperCase().indexOf(filter) > -1) {
            cont++;
          }
        }
        if (cont > 0) {
          tr[i].style.display = "";
        } else {
          tr[i].style.display = "none";
        }
      }
    }
  }

  realizadoAuditoriaPrescDetalle(presc: PrescripcionApi, tipoTec, conOrden) {
    try {
      presc.auditoria.ordenes.forEach(item => {
        if (item.orden == conOrden && item.tipoTecnologia == tipoTec) {
          item.realizado = true;
        }
      });
    } catch (error) {
    }
  }

  realizadoAuditoriaTutelaDetalle(tut: TutelaApi, tipoTec, conOrden) {
    try {
      tut.auditoria.ordenes.forEach(item => {
        if (item.orden == conOrden && item.tipoTecnologia == tipoTec) {
          item.realizado = true;
        }
      });
    } catch (error) {
    }
  }

}
